<template>
	<div class="b-page-wrapper">
		<div class="b-head-block b-head-block--link">
			<div class="b-container">
				<div v-if="obj.id && !isLoadingSkeleton" class="b-head-block__wrapper">
					<router-link class="b-link b-link--header" to="/" title="">
						<iconAngleleftSvg class="b-icon b-icon--header icon-angle-left" />
						<span>Назад</span>
					</router-link>
					<h1 class="b-title b-title--h1">
						{{ obj.stage ? obj.stage.name : "" }}
                        <span v-if="obj.stage && obj.stage.name && obj.number">№</span>
                        {{
							obj.number ? obj.number : ""
						}}
				<!-- <a @click="$router.push(`/editCr/${obj.id}`)" class="b-link b-link--header" href="javascript:void(0);" title="">

					<span>Редактировать</span>
				</a> -->
					</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-window b-window--information">
				<div class="b-form b-form--information" action="/">
					<div class="b-form__first-lavel b-form__first-lavel--empty">


						<!-- ЛОДЕР СКЕЛЕТОН -->
						<div
                            v-show="isLoadingSkeleton"
                            v-for="(item, key) in listSkelLoader"
                            :key="'d'+key"
                            class="b-form__group"
                        >
							<div class="b-form__left">
								<skeleton-loader-vue type="rect" rounded :width="300" :height="15" animation="fade" />
							</div>
							<div class="b-form__right">
								<skeleton-loader-vue type="rect" rounded :width="450" :height="15" animation="fade" />
							</div>
						</div>

                        <div v-if="!obj.id && !isLoadingSkeleton" class="information">
                            Общественное обсуждение по данной клинической рекомендации завершено, <router-link class="link-back" to="/"> вернитесь к списку</router-link>.
                        </div>

						<!-- Стадия -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<span>Стадия</span>
							</div>
							<div class="b-form__right">
								<span >{{ obj.stage ? obj.stage.name : "&mdash;" }}</span>
							</div>
						</div>
						<!-- Статус -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<span>Статус</span>
							</div>
							<div class="b-form__right">
								<span>{{ obj.status ? obj.status.name : "&mdash;" }}</span>
							</div>
						</div>
						<!-- Тип подачи уведомления/заявления -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<label class="b-label">
									Тип подачи
									<span v-if="obj.stage.id == 1"> уведомления</span>
									<span v-else> заявления</span>
								</label>
							</div>
							<div class="b-form__right">
								<span>{{ obj.type ? obj.type.name : "&mdash;" }}</span>
							</div>
						</div>
						<!-- Дата создания уведомления/заявления -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<label class="b-label">
									Дата создания
									<span v-if="obj.stage.id == 1"> уведомления</span>
									<span v-else> заявления</span>
								</label>
							</div>
							<div class="b-form__right">
								<time datetime="19.08.2019">{{
									obj.date ? new Date(obj.date).toLocaleDateString() : "&mdash;"
								}}</time>
							</div>
						</div>
						<!-- Номер уведомления/заявления -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<label class="b-label">
									Номер
									<span v-if="obj.stage.id == 1"> уведомления</span>
									<span v-else> заявления</span>
								</label>
							</div>
							<div class="b-form__right">
								<span>№{{ obj.number }}</span>
							</div>
						</div>
						<!-- Наименование КР -->
						<div v-if="computedShow([1,2,3,4,5,6], [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Наименование КР</span></div>
							<div class="b-form__right">
								<span>{{ obj.name ? obj.name : "-" }}</span>
							</div>
						</div>
						<!-- Связь с Перечнем -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Связь с Перечнем</span></div>
							<div class="b-form__right">
								<span>{{
									obj.serviceType ? obj.serviceType.name : "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- Наименование заболевания по Перечню -->
						<!-- <div v-if="computedShow([1,2,3,4,5,6], [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30])" class="b-form__group">
							<div class="b-form__left">
								<span>Наименование заболевания по Перечню</span>
							</div>
							<div class="b-form__right">
								<span>{{ obj.mkb ? obj.mkb.name : "&mdash;" }}</span>
							</div>
						</div> -->
						<!-- Кодирование по МКБ-10 (из Перечня) -->
						<!-- <div v-if="computedShow([1,2,3,4,5,6], [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30])" class="b-form__group">
							<div class="b-form__left">
								<span>Кодирование по МКБ-10 (из Перечня)</span>
							</div>
							<div class="b-form__right">
								<span>{{ obj.mkb ? obj.mkb.code : "&mdash;" }}</span>
							</div>
						</div> -->
						<!-- Возрастная группа из Перечня -->
						<!-- <div v-if="computedShow([1,2,3,4,5,6], [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30])" class="b-form__group">
							<div class="b-form__left">
								<span>Возрастная группа из Перечня</span>
							</div>
							<div class="b-form__right">
								<span>{{
									obj.ageGroups.length !== 0
										? "(" +
											Array.prototype.map
												.call(obj.ageGroups, function (item) {
													return item.name;
												})
												.join(", ") +
											")"
										: "&mdash;"
								}}</span>
							</div>
						</div> -->
						<!-- Наименование заболевания/состояния  -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Наименование заболевания/состояния </span></div>
							<div class="b-form__right">
								<span>{{ obj.mkb ? obj.mkb.name : "&mdash;"}}</span>
							</div>
						</div>
						<!-- Код МКБ-10 -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Код МКБ-10</span></div>
							<div class="b-form__right">
								<span>{{
									obj.mkbCodes.length !== 0
										? Array.prototype.map
												.call(obj.mkbCodes, function (item) {
													return item.code;
												})
												.join(", ")
										: "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- Возрастная группа -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Возрастная группа</span></div>
							<div class="b-form__right">
								<span>{{
									obj.ageGroups.length !== 0
										? Array.prototype.map
												.call(obj.ageGroups, function (item) {
													return item.name;
												})
												.join(", ")
										: "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- Наименование разработчика -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<span>Наименование разработчика</span>
							</div>
							<div class="b-form__right">
								<span>{{ obj.developer ? obj.developer.name : "&mdash;" }}</span>
							</div>
						</div>
						<!-- Соавторы -->
						<div v-if="computedShow([1,2,3,4,5,6], [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Соавторы</span></div>
							<div class="b-form__right">
								<span>{{
									obj.developers.length !== 0
										? Array.prototype.map
												.call(obj.developers, function (item) {
													return item.name;
												})
												.join(", ")
										: "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- Адрес разработчика -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Адрес разработчика</span></div>
							<div class="b-form__right">
								<span>{{
									obj.developerAddress ? obj.developerAddress : "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- Телефон разработчика -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>Телефон разработчика</span></div>
							<div class="b-form__right">
								<span>{{
									obj.developerPhone ? obj.developerPhone : "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- E-mail разработчика -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left"><span>E-mail разработчика</span></div>
							<div class="b-form__right">
								<span>{{
									obj.developerEmail ? obj.developerEmail : "&mdash;"
								}}</span>
							</div>
						</div>
						<!-- прикрепленный файл -->
						<div v-if="computedShow([1,2,3,4,5,6], [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]) && !isLoadingSkeleton" class="b-form__group">
							<div class="b-form__left">
								<span>Файл</span>
							</div>
							<div class="b-form__right">
								<div class="b-file">
									<!-- <a href="javascript:void(0);" @click="downloadFile(obj.id)" title="Скачать файл">
										<iconDownloadSvg class="b-icon b-icon--report icon-download" />
										<span>Файл</span>
									</a> -->
									<a
									v-if="fileCr.id"
									:href="`${this.apiUrlPortal}Files/GetProjectFile/${fileCr.id}`"
									title="Файл"
									>
										<iconDownloadSvg class="b-icon b-icon--report icon-download" />
										<span>Файл</span></a
										>
									<span v-else>&mdash;</span>
								</div>
							</div>
						</div>
						<!-- Результаты общественного обсуждения -->
						<div v-if="computedShow([1,2,3,4,5,6], [10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,28,29,30])" class="b-form__group">
							<div class="b-form__left">
								<span>Результаты общественного обсуждения</span>
							</div>
							<div class="b-form__right">
								<div class="b-file">
									<!-- <a href="javascript:void(0);" @click="downloadFile(obj.id)" title="Скачать файл">
										<iconDownloadSvg class="b-icon b-icon--report icon-download" />
										<span>Файл</span>
									</a> -->
									<a
									v-if="fileRes.id"
									:href="`${this.apiUrlPortal}Files/GetProjectFile/${fileRes.id}`"
									title="Результаты общественного обсуждения"
									>
										<iconDownloadSvg class="b-icon b-icon--report icon-download" />
										<span>Результаты общественного обсуждения</span></a
										>
									<span v-else>&mdash;</span>
								</div>
							</div>
						</div>
					</div>

					<div v-if="computedChangeStatus(null, this.obj.status.id, null).length > 0" class="b-form__group b-form__group--button">
						<button v-for="(item, ind) in computedChangeStatus(null, this.obj.status.id, null)" @click="dialogForPortal=!dialogForPortal" :class="{'b-button':true, 'b-button--fill':ind == 0, 'b-button--cancel':ind == 1, 'b-button--full':true}">
							<span>{{item.name}}</span>
						</button>
					</div>
				</div>
			</div>
			<!-- комментарии -->
			<!-- <div class="b-form" action="/">
				<div class="b-comment-block">
					<p class="b-title b-title--comment">Комментарии<span>, 3</span></p>
					<div class="b-comment">
						<div class="b-comment__wrapper">
							<div class="b-comment__line">
								<p class="b-comment__person">Константин Константинопольский</p>
								<span class="b-comment__date">07.10.2020 в 16:12 </span>
							</div>
							<p class="b-comment__text">
								Разнообразный и богатый опыт дальнейшее развитие различных форм
								деятельности играет важную роль в формировании позиций,
								занимаемых участниками в отношении поставленных задач.
								Повседневная практика показывает, что начало повседневной работы
								по формированию позиции играет важную роль в формировании систем
								массового участия. Задача организации, в особенности же рамки и
								место обучения кадров влечет за собой процесс внедрения и
								модернизации систем массового участия.
							</p>
							<div class="b-comment__link-block">
								<a
									class="b-link b-link--comment"
									href="javascript:void(0);"
									title="Ответить"
									>Ответить</a
								><a
									class="b-link b-link--comment"
									href="javascript:void(0);"
									title="Цитировать"
									>Цитировать</a
								>
							</div>
							<i class="b-icon b-icon--answer icon-answer"></i>
						</div>
						<div class="b-comment b-comment--answer">
							<div class="b-comment__wrapper b-comment__wrapper--answer">
								<div class="b-comment__line">
									<p class="b-comment__person">Татьяна Михайловна</p>
									<span class="b-comment__date">07.10.2020 в 16:12 </span>
								</div>
								<blockquote class="b-comment__blockquote">
									«Разнообразный и богатый опыт дальнейшее развитие различных
									форм деятельности играет важную роль в формировании позиций,
									занимаемых участниками в отношении поставленных задач»
								</blockquote>
								<p class="b-comment__text">
									Разнообразный и богатый опыт дальнейшее развитие различных
									форм деятельности играет важную роль в формировании позиций,
									занимаемых участниками в отношении поставленных задач.
								</p>
								<div class="b-comment__link-block">
									<a
										class="b-link b-link--comment"
										href="javascript:void(0);"
										title="Ответить"
										>Ответить</a
									><a
										class="b-link b-link--comment"
										href="javascript:void(0);"
										title="Цитировать"
										>Цитировать</a
									>
								</div>
								<i class="b-icon b-icon--answer icon-answer"></i>
							</div>
						</div>
					</div>
					<div class="b-comment">
						<div class="b-comment__wrapper">
							<div class="b-comment__line">
								<p class="b-comment__person">Константин Константинопольский</p>
								<span class="b-comment__date">07.10.2020 в 16:12 </span>
							</div>
							<p class="b-comment__text">
								Разнообразный и богатый опыт дальнейшее развитие различных форм
								деятельности играет важную роль в формировании позиций,
								занимаемых участниками в отношении поставленных задач.
								Повседневная практика показывает, что начало повседневной работы
								по формированию позиции играет важную роль в формировании систем
								массового участия. Задача организации, в особенности же рамки и
								место обучения кадров влечет за собой процесс внедрения и
								модернизации систем массового участия.
							</p>
							<div class="b-comment__link-block">
								<a
									class="b-link b-link--comment"
									href="javascript:void(0);"
									title="Ответить"
									>Ответить</a
								><a
									class="b-link b-link--comment"
									href="javascript:void(0);"
									title="Цитировать"
									>Цитировать</a
								>
							</div>
							<i class="b-icon b-icon--answer icon-answer"></i>
						</div>
					</div>
					<p class="b-title b-title--comment-add b-title--comment">
						Добавить комментарий
					</p>
					<div class="b-input b-input--comment">
						<input
							class="b-input__input-field b-input__input-field--comment"
							id="textarea-notify-before"
							type="textarea"
							placeholder=""
							name="textarea-notify-before"
						/>
						<i class="b-icon b-icon--check icon-check" />
						<i class="b-icon b-icon--close icon-close" />
						<!-- <img class="b-input__img b-input__img--tablet" src="/images/inhtml/edit-icon-tablet.jpg" alt="icon" title=""/>
							<img class="b-input__img b-input__img--mobile" src="/images/inhtml/edit-icon-mobile.jpg" alt="icon" title=""/>
					</div>
					<button class="b-button b-button--fill">Отправить</button>
				</div>
			</div> -->
		</div>


		<transition v-if="dialogForPortal" name="modal">
			<div class="modal-mask">
				<div class="modal-wrapper">
					<div class="modal-container">
						<div class="modal-header">
							<slot name="header">
								Отправить предложение
							</slot>
						</div>

						<div class="modal-body">
							<slot name="body">
								<label class="label">
									<p style="margin-bottom:7px">Наименование КР</p>
									<input v-model="obj.name" class="inp" readonly type="text">
								</label>

								<label class="label">
									<p style="margin-bottom:7px">Разработчик КР</p>
									<input v-model="obj.developer.name" class="inp" readonly type="text">
								</label>

								<label class="label">
									<p style="margin-bottom:7px">ФИО пользователя</p>
									<input class="inp" v-model="user.fio" type="text">
								</label>

								<label class="label">
									<p style="margin-bottom:7px">E-mail пользователя</p>
									<input class="inp" v-model="user.email" type="text">
								</label>

								<label class="label">
									<p style="margin-bottom:7px">Комментарий</p>
									<textarea class="inp" v-model="user.comment" rows="4" />
								</label>

								<button v-show="!fileObs" @click="$refs['fileObs'].click()" class="label-marg b-button b-button--fill b-button--full b-button--file" >
									<iconDownloadSvg style="fill:#fff" class="b-icon b-icon--report icon-download" />
									<!-- <iconFileSvg class="b-icon b-icon--file icon-file" /> -->
									<input accept=".doc, .docx, application/pdf" v-show="false" type="file" ref="fileObs" @change="handleFileUpload('fileObs')"/>
									Добавить файл
								</button>

								<div v-show="fileObs" class="label-marg b-file class-relative">
									<a style="text-transform:none" class="b-file__name" href="javascript:void(0);" :title="fileObs&&fileObs.name">
										<iconFileSvg class="b-icon b-icon--file icon-file" />
										<span>{{fileObs&&fileObs.name}}</span>
									</a>
									<iconCloseSvg @click="resetFile('fileObs')" style="float:right;cursor:pointer" class="b-icon b-icon--close icon-close class-custom" />
								</div>
							</slot>
						</div>

						<div class="modal-footer">
							<slot name="footer">
								<button :disabled="disabledBtn" class="b-button b-button--success" @click="save(user, obj.id, fileObs)">Отправить</button>
								<button :disabled="disabledBtn" class="b-button b-button--cancel ml-2" @click="close('fileObs')">Отменить</button>
							</slot>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import iconSvg from "../../assets/icons/svg/filter.svg";
import iconCloseSvg from "../../assets/icons/svg/close.svg";
import iconSearchSvg from "../../assets/icons/svg/search.svg";
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg";
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg";
import iconDownloadSvg from "../../assets/icons/svg/download.svg";
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg";
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg";
import iconCalendarSvg from "../../assets/icons/svg/calendar.svg";
import iconFileSvg from "../../assets/icons/svg/file.svg";

import skeletonLoaderVue from 'skeleton-loader-vue';
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		iconSvg,
		iconSearchSvg,
		iconAngleDownSvg,
		iconCloseSvg,
		iconDownloadSvg,
		iconSortdownSvg,
		iconSortupSvg,
		iconAngleleftSvg,
		iconCalendarSvg,
		iconFileSvg,
		skeletonLoaderVue
	},
	data: () => ({
		disabledBtn: false,
		isLoadingSkeleton: true,
		listSkelLoader: [{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
		fileObs: null,
		user: {
			fio: '',
			email: '',
			comment: ''
		},
		dialogForPortal: false,
		obj: {
			number: null,
			name: null,
			stage: { id: null, name: null },
			status: { id: null, name: null },
			type: { id: null, name: null },
			serviceType: null,
			mkb: null,
			date: null,
			startPublicDiscussionDate: null,
			finalPublicDiscussionDate: null,
			expertiseResult: { id: null, name: null },
			npsDecision: { id: null, name: null },
			locationAddress: null,
			protocolDate: null,
			protocolNumber: null,
			developer: { id: null, name: null },
			developerPhone: null,
			developerEmail: null,
			developerAddress: null,
			publicationAddress: null,
			rubricatorId: null,
			mkbCodes: [],
			ageGroups: [],
			developers: [],
			id: null,
			createdOn: null,
			modifiedOn: null,
			createdBy: null,
			modifiedById: null,
			author: null,
		},
		devList: {},
		fileCr: { id: null },
		fileRes: { id: null },
	}),
	computed: {
		...mapGetters({
			getUserInfo: 'auth/getUserInfo',
			apiUrl: 'apiUrl',
			apiUrlPortal: 'apiUrlPortal'
		})
	},
	methods: {
		...mapActions({
			apiAll: "api/apiAll",
		}),
		close(fileName) {
			this.user = {
				fio: '',
				email: '',
				comment: ''
			}
			// this.$refs[fileName].value = ''
			this[fileName] = null

			this.dialogForPortal = false
			this.disabledBtn = false
		},
		computedShow(stage, status) {
			return stage.find(f => f == this.obj.stage.id) && status.find(f => f == this.obj.status.id)
		},
		computedChangeStatus(role, status, stage) {
			let arr = [];
			if(status == 31) arr.push({name: 'Отправить предложение', nextStatus: null})

			return arr
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase())
		},
		save(user, id, file) {
			if(!this.validateEmail(user.email)) {
				this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Заполните корректно поле E-mail' })
				return;
			}

			if(user.fio && user.email && user.comment) {
				this.disabledBtn = true
				let data = new FormData()
				if(file) data.append('File', file)

				this.api.post(`${this.apiUrlPortal}Comments`, {
					projectId: id,
					commentText: user.comment,
					authorName: user.fio,
					authorEmail: user.email
				})
				.then(res => {
					if(res.status == 200) {
						if(file) {
							data.append('CommentId', res.data.commentId)
							this.api.post(`${this.apiUrlPortal}Files/AddCommentFile`, data)
							.then((res2) => {
								if(res2.status == 200) {
									//рассылка почты
									this.api.get(`${this.apiUrlPortal}EmailNotifiers/Comment/${res.data.commentId}`)

									this.user = {fio:'', email:'',comment:''}
									this.$refs['fileObs'].value = ''
									this.dialogForPortal = false
									this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успешно', text: 'Предложение успешно отправлено' })

									this.close('fileObs')
								}
							})
						} else {
							//рассылка почты
							this.api.get(`${this.apiUrlPortal}EmailNotifiers/Comment/${res.data.commentId}`)
							.then(res => {
								if(res.status == 200) {
									this.user = {fio:'', email:'',comment:''}
									this.dialogForPortal = false
									this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успешно', text: 'Предложение успешно отправлено' })

									this.close('fileObs')
								}
							})
						}
					}
				})
			} else {
				this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при отправке', text: 'Заполните все поля...' })
			}
		},
		downloadFile(id) {
			this.api.get(`${this.apiUrlPortal}Files/GetProjectFiles/${id}`)
			// this.api.get(`${this.apiUrlPortal}Files/GetProjectFile/${id}`, { responseType: 'blob' })
			// .then((response) => {
			// 	const blob = new Blob([response.data], {type: response.data.type});
			// 	const url = window.URL.createObjectURL(blob);
			// 	const link = document.createElement('a');
			// 	link.href = url;
			// 	const contentDisposition = response.headers['content-disposition'];
			// 	let fileName = this.obj.name;
			// 	if (contentDisposition) {
			// 		const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
			// 		if (fileNameMatch.length === 2)
			// 			fileName = fileNameMatch[1];
			// 	}
			// 	link.setAttribute('download', fileName);
			// 	document.body.appendChild(link);
			// 	link.click();
			// 	link.remove();
			// 	window.URL.revokeObjectURL(url);
			// })
			// .catch((error) => console.log(error))
		},
		handleFileUpload(fileName) {
			this[fileName] = this.$refs[fileName].files[0]
		},
		resetFile(fileName) {
			if(confirm('Вы уверены что хотите удалить прикрепленный файл?')) {
				this.$refs[fileName].value = ''
				this[fileName] = null
			}
		},
	},
	created() {
        // if () {
            
        // }

		this.api.get(`${this.apiUrlPortal}Projects/${this.$route.params.id}`)
		.then(res => {
			if(res.status == 200) {
                let statusId = res.data.status.id;

                if(statusId !== 31 && statusId !== 32 && statusId !== 34) {
                    this.isLoadingSkeleton = false
                    return;
                }

				this.obj = res.data
				this.isLoadingSkeleton = false

				this.api.get(`${this.apiUrlPortal}Files/GetProjectFiles/${this.$route.params.id}`)
				.then(res2 => {
					this.fileCr.id = res2.data.find(f=> f.typeId == 10) ? res2.data.find(f=> f.typeId == 10).id : null
					this.fileRes.id = res2.data.find(f=> f.typeId == 8) ? res2.data.find(f=> f.typeId == 8).id : null
				})
			}
		})
	},
};
</script>

<style>
@import "../../style/stylePages.css";

.link-back {
    color: blue;
    text-transform: none;
    text-decoration: underline;
}

.information {
    height: 50px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 700px;
  margin: 0px auto;
  padding: 0px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-header {
	text-align: center;
	background: #003b84;
	color: white;
	font-size: 19px;
	border-bottom: 1px solid lightgray;
	padding: 10px;
}
.modal-footer {
	text-align: center;
	padding: 10px 20px;
}

.modal-body {
    margin: 20px 0;
	padding: 10px 60px;
}

.label {
	font-size: 18px;
}
.label-marg {
	margin-top: 15px;
}

.inp {
	padding: 10px;
	border-radius: 10px;
}
.inp:hover {
	background-color: #fafafa;
	box-shadow:0 0 4px rgba(0,0,0,0.3);
	transition: .3s box-shadow;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
